import { z } from "zod";

export const appDataSchema = z.any();

export const appKeysSchema = z.object({});

const credentialSchema = z.object({
  website_url: z.string().url().optional(),
  custom_api_url: z.string().url().optional(),
  custom_api_key: z.string().optional(),
  shopify_url: z.string().url().optional(),
  shopify_api_key: z.string().optional(),
  shopify_api_secret: z.string().optional(),
  shopify_access_token: z.string().optional(),
  shopify_api_version: z.string().optional(),
});

export const ShopifyCustomerSchema = z.object({
  email: z.string().email(),
  first_name: z.string().optional(),
  last_name: z.string().optional(),
});

export const vpqSchema = z.object({
  "question13a-hairTexture": z.string(),
  hairTexture: z.string(),
  hairTypeId: z.string(),
  prakruti: z.string(),
  hairTextureId: z.string(),
  "question20-appetite": z.string(),
  date: z.string(),
  "question16-hairColor": z.string(),
  userId: z.string(),
  prakrutiId: z.string(),
  "question13-scalpTexture": z.string(),
  "question1-bodyFrame": z.string(),
  "question11-hairVolume": z.string(),
  "question1-name": z.string(),
  hairType: z.string(),
  "question4-bodySweat": z.string(),
  "question12-hairType": z.string(),
  "question15-hairTreatment": z.string(),
  scalpTypeId: z.string(),
  scalpType: z.string(),
  question1c: z.string(),
  type: z.string(),
  question1b: z.string(),
  customerId: z.string(),
  "question14-secondaryHairIssue": z.string().optional(),
});

export const hairRecosSchema = z.object({
  handle: z.string(),
  hairTexture: z.string(),
  hairTypeId: z.string(),
  hairType: z.string(),
  hairTextureId: z.string(),
  gender: z.string(),
  date: z.string(),
  userId: z.string(),
  scalpTypeId: z.string(),
  scalpType: z.string(),
  vpqDate: z.string(),
  id: z.string(),
  customerId: z.string(),
});

export const ddbCustomerSchema = z.object({
  allVPQs: vpqSchema.array(),
  allRecos: hairRecosSchema.array(),
});

export type ShopifyCustomer = z.infer<typeof ShopifyCustomerSchema>;

export type ddbCustomer = z.infer<typeof ddbCustomerSchema>;

export type Vpq = z.infer<typeof vpqSchema>;

export type VedixCredentialSchema = z.infer<typeof credentialSchema>;
